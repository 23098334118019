import axios from './axios'

// 获取快递公司列表
export const getExpressList = () => axios.get('/management/deliver')

// 可选的快递公司（添加编辑快递公司使用）
export const canUseExpressList = () => axios.get('/management/express')

// 添加快递公司
export const addExpress = (data) => axios.post('/management/deliver', data)

// 编辑快递公司
export const editExpress = (data) => axios.put('/management/deliver', data)

// 删除快递公司
export const deleteExpress = (id) => axios.delete(`/management/deliver/${id}`)

// 快递公司详情
export const detailExpress = (id) => axios.get(`/management/deliver/${id}`)

// 获取可使用的快递公司列表，修改快递单号使用
export const editCourierNumber = () => axios.get('/management/deliver')
