<template>
  <el-dialog title="修改快递公司" :visible.sync="visible" :append-to-body="true" :before-close="cancel" width="600px">
    <div class="center">
      <div class="item">
        <span>快递模板</span>
        <el-select v-model="expressId" filterable>
          <el-option v-for="item in expressList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="item" style="margin-top: 20px;" v-if="type === 2">
        <span>快递单号</span>
        <el-input v-model="deliveryCode"></el-input>
      </div>
    </div>
    <div class="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as expressService from '@/api/express'
import * as orderService from '@/api/order'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: Number,
      require: true
    },
    type: {
      type: Number,
      require: true
    }
  },
  data () {
    return {
      expressList: [],
      expressId: '',
      deliveryCode: ''
    }
  },
  mounted () {
    this.getExpressList()
  },
  methods: {
    async getExpressList () {
      const res = await expressService.editCourierNumber()
      this.expressList = res
    },
    cancel () {
      this.$emit('onCancel')
      this.expressId = ''
      this.deliveryCode = ''
    },
    async confirm () {
      if (!this.expressId) {
        this.$notify.error({ title: '请先选择快递公司！' })
        return
      }

      if (this.type === 1) {
        // 未发货的指定物流发货
        try {
          await orderService.selectSend(this.orderId, this.expressId)
          this.$notify.success({ title: '发货成功' })
          this.cancel()
          this.$emit('confirm')
        } catch (err) {
          this.$notify.error({ title: '发货失败' })
        }
      } else if (this.type === 2 && this.deliveryCode) {
        // 已经发过货的修改物流（不发货）
        try {
          await orderService.changeExpress(this.orderId, this.expressId, this.deliveryCode)
          this.$notify.success({ title: '修改成功' })
          this.cancel()
          this.$emit('confirm')
        } catch (err) {
          this.$notify.error({ title: '修改失败' })
        }
      } else if (this.type === 2 && !this.deliveryCode) {
        // 已经发过货的发货
        try {
          await orderService.changeExpressSend(this.orderId, this.expressId)
          this.$notify.success({ title: '发货成功' })
          this.cancel()
          this.$emit('confirm')
        } catch (err) {
          this.$notify.error({ title: '发货失败' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
  margin-bottom: 20px;
  .item {
    display: grid;
    grid-template-columns: 100px auto ;
    line-height: 32px;
  }
}
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 20px;
  text-align: right;
}
</style>
