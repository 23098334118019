<template>
  <div class="container">
    <span
      v-for="item in tabs"
      :key="item.key"
      class="tab"
      :class="{'tab-active': active === item.key }"
      @click="handleClick(item)"
    >
    {{item.name}}
    </span>
  </div>
</template>

<script>
// import * as R from 'ramda'

export default {
  props: {
    type: {
      default: 'default',
      type: String
    },
    value: {
      default: 'all',
      type: String
    }
  },
  data () {
    return {
      active: this.value
    }
  },
  computed: {
    tabs () {
      return this.type === 'bulk'
        ? [
          { name: '全部', key: 'all' },
          { name: '待支付', key: '0' },
          { name: '待结单', key: 'wait' },
          { name: '待发货', key: '1' },
          { name: '待收货', key: '2' },
          { name: '已完成', key: '3' },
          { name: '已关闭', key: '5' }
        ]
        : [
          { name: '全部', key: 'all' },
          { name: '待支付', key: '0' },
          { name: '待发货', key: '1' },
          { name: '待收货', key: '2' },
          { name: '已完成', key: '3' },
          { name: '已关闭', key: '5' }
        ]
    }
  },
  methods: {
    handleClick (data) {
      this.active = data.key
      this.$emit('onChange', data)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/layout.scss';
.container {
  text-align: left;
  border-bottom: 1px solid $borderColor;
  width: 100%;
  height: 70px;
  margin-bottom: 20px;
  padding-left: 50px;
  box-sizing: border-box;
}

.tab {
  display: inline-block;
  line-height: 66px;
  color: #ACADB7;
  font-weight: 500;
  font-size: 20px;
  margin-right: 70px;
  cursor: pointer;
  box-sizing: content-box;

  &-active  {
    border-bottom: 4px solid #1D90FBFF;
  }
}

</style>
