import * as R from 'ramda'

export const statusMap = {
  0: '待付款',
  1: '待发货',
  2: '待收货',
  3: '已完成',
  4: '售后',
  5: '已关闭'
}

export const cancelTypeMap = {
  0: '买错了',
  1: '暂时不需要了',
  2: '一直没有发货',
  3: '其他'
}

export const getTotal = (id, orders) => {
  const targets = orders.filter(o => o.pid === id)
  return targets.reduce((a, c) => {
    // a += c.
    if (c.skuList && !R.isEmpty(c.skuList)) {
      c.skuList.forEach(item => {
        a += item.quantity
      })
    }
    return a
  }, 0)
}
