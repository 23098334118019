<template>
  <el-dialog
    title="物流信息"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="cancel"
    width="600px"
  >
    <div class="content">
      <template v-if="data && data.length > 0">
        <div v-for="item in data" :key="item.acceptTime" class="record">
          <p style="width: 160px;">{{ item.acceptTime }}：</p>
          <p style="flex: 1;">{{ item.acceptStation }}</p>
        </div>
      </template>
      <p v-else class="noExpress">暂无物流信息</p>
    </div>
    <div class="footer">
      <el-button type="primary" @click="cancel">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  naem: 'express',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    data: {
      default () {
        return []
      },
      type: Array
    }
  },
  data () {
    return {}
  },
  methods: {
    cancel () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog__body {
  padding: 0 20px;
  .content {
    height: calc(50vh);
    overflow-y: auto;
  }
  .footer {
    border-top: 1px solid #d9e0f0ff;
    padding: 20px 0;
    text-align: right;
  }
}

.record {
  margin: 10px auto;
  color: #7d7e8e;
  line-height: 20px;
  display: flex;
}
.noExpress {
  margin-top: 50px;
  text-align: center;
  font-size: 16px;
}
</style>
