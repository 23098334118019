<template>
  <el-dialog
    title="添加备注"
    :visible.sync="visible"
    :before-close="cancel"
    :append-to-body="true"
    width="600px"
  >
    <field label="备注：" :asterisk="true" labelWidth="110px" :validation="validation.remark">
      <el-input
        type="textarea"
        placeholder="请输入备注"
        v-model="formData.remark"
        maxlength="100"
        show-word-limit
      />
    </field>
    <div class="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import * as R from 'ramda'
const init = {
  remark: ''
}

export default {
  naem: 'editRole',
  props: {
    visible: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      formData: R.clone(init)
    }
  },
  mixins: [validation({
    rules: {
      remark: [v.required('请输入备注')]
    },
    field: 'formData'
  })],
  methods: {
    async confirm () {
      try {
        await this.$validate()
        this.$emit('onConfirm', R.clone(this.formData))
        this.formData = R.clone(init)
      } catch (err) {
        // handle err
      }
    },
    cancel () {
      this.$emit('onCancel')
      this.formData = R.clone(init)
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 30px;
  text-align: center;
}

</style>
