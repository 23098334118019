import axios from './axios'

export const list = params => axios.get('/management/order/normal', { params })
export const detail = id => axios.get(`/management/order/${id}`)
export const send = id => axios.post(`/management/order/send/${id}`)
export const batchSend = ids => axios.post(`/management/order/send-batch?ids=${ids}`)
export const addRemark = (id, postData) => axios.post(`/management/order/remark/${id}?remark=${postData.remark}`)
export const cancelOrder = (id, postData) => axios.post(`/management/order/cancel/${id}?reason=${postData.reason}`)
export const getExpressDetail = id => axios.get(`/management/order/trace/${id}`)

export const bulkOrderList = params => axios.get('/management/order/group', { params })
export const afterSaleOrderList = params => axios.get('/management/order/post-sale', { params })
// 批量打印小票
export const printReceipts = (ids) => axios.post(`/management/order/print-receipt?ids=${ids}`)
// 订单结单
export const statement = (ids) => axios.put(`/management/order/check?ids=${ids}`)
// 指定快递模板发货
export const selectSend = (id, companyId) => axios.post(`/management/order/send/${id}?deliveryCompanyId=${companyId}`)
// 修改快递公司（不发货）
export const changeExpress = (ids, companyId, deliverCode) => axios.put(`/management/order/change-express/manual?ids=${ids}&deliverCompanyId=${companyId}&deliverCode=${deliverCode}`)
// 修改快递公司（发货）
export const changeExpressSend = (id, companyId) => axios.put(`/management/order/change-express?id=${id}&deliverCompanyId=${companyId}`)
